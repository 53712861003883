import React from "react";
import AboutMe from "../AboutMe/AboutMe";
import Projects from "../Projects/Projects";
import ContactUs from "../ContactUs/ContactUs";
import Banner from "../Banner/Banner";
const Home = () => {
  return (
    <>
      {/* className="container" */}
      <div>
        <Banner />
        <AboutMe />
        <Projects />
        <ContactUs />
      </div>
    </>
  );
};

export default Home;
