import React from "react";

const Blogs = () => {
  return (
    <div className="h-screen flex items-center justify-center">
      <h2 className="text-3xl lg:text-8xl text-center">Comming Soon...</h2>
    </div>
  );
};

export default Blogs;
