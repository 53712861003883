import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useParams } from "react-router-dom";
import { BiCodeAlt } from "react-icons/bi";
import { TbWorld } from "react-icons/tb";
import { FaServer } from "react-icons/fa";

const ProjectDetails = () => {
  const { id } = useParams();
  const [project, setProject] = useState({});

  useEffect(() => {
    fetch(`/projects.json`)
      .then((res) => res.json())
      .then((data) => {
        const isExist = data.find((project) => project?._id === id);
        if (isExist) {
          setProject(isExist);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, [id]);

  // const project = useLoaderData();
  const { title, subTitle, imgs, features } = project || {};

  return (
    <div className="px-10 mx-auto mb-10 lg:mb-0">
      <h2 className="text-center text-3xl lg:text-5xl text-primary py-10 font-bold uppercase">
        <a href={project?.live}>{title}</a>
      </h2>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-10">
        <div className="w-full order-last lg:order-first">
          <Carousel>
            {imgs?.map((img, i) => (
              <div key={i}>
                <p>{img?.slice(25, 100)}</p>
                <img src={img} className="w-full" alt="" />
              </div>
            ))}
          </Carousel>
        </div>
        {/* Description starts */}
        <div className="w-full order-first lg:order-last">
          <div>
            <h2 className="text-3xl font-bold my-2">
              <a href={project?.live}>{title}</a>{" "}
            </h2>
            <h5 className="text-xl italic">{subTitle}</h5>
          </div>

          <h3 className="text-xl my-5 font-medium">Technologies</h3>
          <div className="">
            {project?.technologies?.map((tech, i) => (
              <button
                key={i}
                className="text-sm lg:text-md px-3 py-1 bg-black text-white rounded-3xl m-1"
              >
                {tech}
              </button>
            ))}
          </div>
          <h3 className="text-xl my-5 font-medium">Links</h3>
          <div className="flex justify-evenly lg:justify-start my-5 gap-x-5">
            {project?.live && (
              <a
                href={project?.live}
                className="bg-primary w-10 h-10 rounded-full text-white flex items-center justify-center"
                target={"_blank"}
                rel="noreferrer"
              >
                <TbWorld size={26} />
              </a>
            )}
            {project?.client && (
              <a
                href={project?.client}
                className="bg-primary w-10 h-10 rounded-full text-white flex items-center justify-center"
                target={"_blank"}
                rel="noreferrer"
              >
                <BiCodeAlt size={26} />
              </a>
            )}
            {project?.server && (
              <a
                href={project?.server}
                className="bg-primary w-10 h-10 rounded-full text-white flex items-center justify-center"
                target={"_blank"}
                rel="noreferrer"
              >
                <FaServer size={22} />
              </a>
            )}
          </div>
          <div className="my-5">
            <h3 className="text-xl my-5 font-medium">Features</h3>
            <ul className="px-5 text-justify lg:px-10">
              {features &&
                features?.map((feature, i) => (
                  <span key={i}>
                    <li className={`${feature && "list-disc"}`}>{feature}</li>{" "}
                    <br />
                  </span>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
