import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import NavIcon from "../Pages/NavIcon/NavIcon";

const Main = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  }, []);

  if (isLoading) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <div className="loader" />
      </div>
    );
  }

  return (
    <>
      <NavIcon />
      <Outlet />
    </>
  );
};

export default Main;
