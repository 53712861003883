import React from "react";

const SectionHeading = ({ title, subTitle }) => {
  return (
    <h2 className="heading" data-aos="zoom-in">
      {title} <span>{subTitle}</span>
    </h2>
  );
};

export default SectionHeading;
