import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BiCodeAlt } from "react-icons/bi";
import { FaServer } from "react-icons/fa";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { TbWorld } from "react-icons/tb";

const Project = ({ project }) => {
  const [hover, setHover] = useState(false);

  const hoverStyle = {
    width: "100%",
    height: "300px",
    background: `url(${project?.thumbnail})`,
    backgroundPosition: "bottom",
    backgroundSize: "cover",
    transition: "ease-in-out 6s",
  };

  const normalStyle = {
    width: "100%",
    height: "300px",
    background: `url(${project?.thumbnail})`,
    backgroundPosition: "top",
    backgroundSize: "cover",
    transition: "ease-in-out 3s",
    borderRadius: "5px",
  };

  const onMouseEnter = () => {
    setHover(true);
  };

  const onMouseLeave = () => {
    setHover(false);
  };

  return (
    <div
      data-aos="fade-up"
      data-aos-duration="3000"
      className="border-2 rounded-lg"
    >
      <div
        style={hover ? hoverStyle : normalStyle}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      <div className="px-5 min-h-[250px] relative">
        <div>
          <h2 className="font-bold text-xl mt-5 text-primary">
            <Link
              className="flex justify-between items-center"
              to={`/project/${project?._id}`}
            >
              {project?.title} <BsFillArrowRightCircleFill />
            </Link>
          </h2>
          <p className="text-base mb-2">{project?.subTitle}</p>
        </div>
        <div>
          {project?.technologies.map((tech, i) => (
            <button
              key={i}
              className="text-xs px-2 py-1 bg-black text-white rounded-3xl m-1"
            >
              {tech}
            </button>
          ))}
        </div>
        <div className="absolute left-[50%] translate-x-[-50%] bottom-1  flex gap-5 justify-between my-3">
          {project?.live && (
            <a
              href={project?.live}
              className="bg-primary w-10 h-10 rounded-full text-white flex items-center justify-center"
              target={"_blank"}
              rel="noreferrer"
            >
              <TbWorld size={26} />
            </a>
          )}
          {project?.client && (
            <a
              href={project?.client}
              className="bg-primary w-10 h-10 rounded-full text-white flex items-center justify-center"
              target={"_blank"}
              rel="noreferrer"
            >
              <BiCodeAlt size={26} />
            </a>
          )}
          {project?.server && (
            <a
              href={project?.server}
              className="bg-primary w-10 h-10 rounded-full text-white flex items-center justify-center"
              target={"_blank"}
              rel="noreferrer"
            >
              <FaServer size={22} />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default Project;
