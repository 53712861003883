import React from "react";
import { ImLocation } from "react-icons/im";
import { FaEnvelope } from "react-icons/fa";
import {
  BsFacebook,
  BsGithub,
  BsTelephoneOutboundFill,
  BsWhatsapp,
} from "react-icons/bs";
import { FaLinkedin } from "react-icons/fa";

const FindMe = () => {
  const social_links = [
    {
      link: "https://www.linkedin.com/in/m-morshedul-islam-100",
      icon: <FaLinkedin size={20} />,
      bgColor: "bg-[#0072b1]",
    },
    {
      link: "https://github.com/mmorshedulislam",
      icon: <BsGithub size={20} />,
      bgColor: "bg-[#24292e]",
    },
    {
      link: "https://wa.me/+8801861952640",
      icon: <BsWhatsapp size={20} />,
      bgColor: "bg-[#25D366]",
    },
    {
      link: "https://facebook.com/mmorshedul.islam.100",
      icon: <BsFacebook size={20} />,
      bgColor: "bg-[#3b5998]",
    },
  ];

  return (
    <div>
      <h2 className="text-2xl lg:text-3xl font-bold my-5">Find Me on</h2>
      <div>
        <p className="flex items-center justify-start gap-3 text-xl">
          <div className="text-primary rounded-full p-2 bg-white">
            <ImLocation />
          </div>
          <a href="https://goo.gl/maps/vUA32KV3v3y3GcVUA">
            Chittagong, Bangladesh.
          </a>
        </p>
        <p className="flex items-center justify-start gap-x-3 text-xl my-3 whitespace-pre-wrap">
          <div className="text-primary rounded-full p-2 bg-white">
            <FaEnvelope />
          </div>
          <a href="mailto:morshed952640@gmail.com">morshed952640@gmail.com</a>
        </p>
        <p className="flex items-center justify-start gap-3 text-xl">
          <div className="text-primary rounded-full p-2 bg-white">
            <BsTelephoneOutboundFill />
          </div>
          <a href="tel:+88 01861952640">+88 01861952640</a>
        </p>
        <h3 className="text-2xl mt-10 font-bold">Find Me on Social</h3>
        <div className="flex mt-3 gap-x-5">
          {social_links.map(({ link, bgColor, icon }, idx) => (
            <a
              href={link}
              key={idx}
              className={`w-10 h-10 flex items-center justify-center rounded-full p-2 text-white ${bgColor}`}
            >
              {icon}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FindMe;
