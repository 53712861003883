import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-hot-toast";
import { useState } from "react";
import FindMe from "./FindMe";
import SectionHeading from "../../components/common/SectionHeading/SectionHeading";
import { FaRegPaperPlane } from "react-icons/fa";
import UseMaxWidth from "../../components/common/UseMaxWidth/UseMaxWidth";

const ContactUs = () => {
  const form = useRef();
  const [processing, setProcessing] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setProcessing(true);

    emailjs
      .sendForm(
        `${process.env.REACT_APP_EMAIL_JS_S_ID}`,
        `${process.env.REACT_APP_EMAIL_JS_T_ID}`,
        form.current,
        `${process.env.REACT_APP_EMAIL_JS_CURRENT}`
      )
      .then(
        (result) => {
          console.log(result.text);
          e.target.reset();
          setProcessing(false);
          toast.success(`Thank You.`);
        },
        (error) => {
          console.log(error.text);
          setProcessing(false);
        }
      );
  };
  return (
    <div className="py-20 lg:px-5 px-0 mb-10 lg:mb-0">
      <SectionHeading title="Get In" subTitle="Touch" />

      <UseMaxWidth>
        <div className="grid grid-cols-1 lg:grid-cols-[2fr_3fr] gap-5 mt-10 px-5 lg:px-16">
          <FindMe />
          <div className="order-first lg:order-last">
            <h2 className="text-2xl lg:text-3xl font-bold my-5">
              Send A Message
            </h2>
            <form ref={form} onSubmit={sendEmail}>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
                <input
                  type="text"
                  name="user_name"
                  className="w-full p-3 rounded-xl border bg-transparent text-white"
                  placeholder="Your Name"
                  required
                />
                <input
                  type="email"
                  name="user_email"
                  className="w-full p-3 rounded-xl border bg-transparent text-white"
                  placeholder="Your Email"
                  required
                />
              </div>
              <input
                type="text"
                name="subject"
                className="w-full p-3 rounded-xl border my-3 bg-transparent text-white"
                placeholder="Your Subject"
                required
              />
              <textarea
                type="text"
                name="message"
                className="w-full p-3 rounded-xl border h-32 bg-transparent text-white"
                placeholder="Your Message"
              />
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="w-full lg:w-fit border border-primary text-white bg-primary px-6 py-2 rounded-lg my-2 text-xl cursor-pointer hover:bg-blue-800 hover:text-white hover:delay-300 flex justify-center items-center gap-2"
                  disabled={processing}
                >
                  <FaRegPaperPlane />
                  {processing ? "Sending..." : "Send"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </UseMaxWidth>
    </div>
  );
};

export default ContactUs;
