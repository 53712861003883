import React from "react";
import { AiFillHome, AiFillMessage } from "react-icons/ai";
import { CgToolbox } from "react-icons/cg";
import { FaUserAlt } from "react-icons/fa";
import { SiBlogger } from "react-icons/si";
import { Link } from "react-router-dom";

const NavIcon = () => {
  return (
    <div className="z-10 fixed lg:fixed lg:right-10 lg:top-[50%] lg:translate-y-[-50%] bottom-[6%] right-[50%] translate-x-[50%]">
      <div className="flex lg:flex-col gap-3">
        <Link
          to={"/"}
          className="rounded-full bg-white font-semibold p-3 text-2xl text-primary"
        >
          <AiFillHome />
        </Link>
        <Link
          to={"/about"}
          className="rounded-full bg-white font-semibold p-3 text-2xl text-primary"
        >
          <FaUserAlt />
        </Link>
        <Link
          to={"/projects"}
          className="rounded-full bg-white font-semibold p-3 text-2xl text-primary "
        >
          <CgToolbox />
        </Link>
        <Link
          to={"/contact"}
          className="rounded-full bg-white font-semibold p-3 text-2xl text-primary"
        >
          <AiFillMessage />
        </Link>
        <Link
          to={"/blogs"}
          className="rounded-full bg-white font-semibold p-3 text-2xl text-primary"
        >
          <SiBlogger />
        </Link>
      </div>
    </div>
  );
};

export default NavIcon;
