import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Projects from "./Pages/Projects/Projects";
import ContactUs from "./Pages/ContactUs/ContactUs";
import Main from "./layouts/Main";
import ProjectDetails from "./Pages/Projects/ProjectDetails";
import Blogs from "./Pages/Blogs/Blogs";
import AboutMe from "./Pages/AboutMe/AboutMe";
import { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import { initGA, logPageView } from "./utils/analytics";
import Aos from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    // for AOS
    Aos.init();

    // Google analytics
    initGA();
    logPageView();
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Main />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/about",
          element: <AboutMe />,
        },
        {
          path: "/projects",
          element: <Projects />,
        },
        // {
        //   path: "/project/:id",
        //   element: <ProjectDetails />,
        //   loader: ({ params }) =>
        //     fetch(
        //       `https://portfolio-server-jet.vercel.app/project/${params.id}`
        //     ),
        // },
        {
          path: "/project/:id",
          element: <ProjectDetails />,
        },
        {
          path: "/contact",
          element: <ContactUs />,
        },
        {
          path: "/blogs",
          element: <Blogs />,
        },
      ],
    },
  ]);

  return (
    <div className="w-full mx-auto">
      <RouterProvider router={router} />
      <Toaster />
    </div>
  );
}

export default App;
