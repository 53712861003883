export const experience = [
  {
    company: "Z-Eight Tech",
    logo: "https://i.ibb.co/8NsJy0v/1653972679402.jpg",
    designation: "Frontend Developer",
    time: "August 2023 - August 2024",
    type: "Onsite",
    status: "Fulltime",
    location: "Chittagong, Bangladesh",
    description:
      "As a dedicated Frontend Developer at Z-Eight Tech, I bring passion and expertise to the dynamic world of web development. Since joining in August 2023, I've been actively contributing to the creation of seamless and visually appealing user interfaces. My role involves crafting responsive and user-friendly designs that enhance the overall user experience.",
  },
  {
    company: "Amelia Soft Ltd",
    logo: "https://i.ibb.co/KyCMLj6/2023-01-24.jpg",
    designation: "Full-Stack (MERN) Developer",
    time: "May 2023 - July 2023",
    type: "Remote",
    status: "Internship",
    location: "Dhaka, Bangladesh.",
    description:
      "During my internship at Amelia Soft Ltd from May 2023 to July 2023, I had the opportunity to dive into the realm of Full-Stack development using the MERN (MongoDB, Express.js, React, Node.js) stack. As a remote intern, I engaged in a dynamic and collaborative environment, contributing to the company's projects and gaining hands-on experience with modern web development technologies.",
  },
];

export const journey = [
  {
    institution: "Phitron",
    logo: "https://i.ibb.co/ypcP15X/images.jpg",
    title: "CSE Fundamentals",
    time: "April 2024 - Present",
    technologies: [
      "C",
      "C+",
      "Data Structure",
      "Algorithm",
      "Problem Solving",
      "Python",
      "django",
    ],
  },
  // {
  //   institution: "Google & Youtube",
  //   logo: "https://i.ibb.co/qDH6nJ8/360-F-474059464-qld-Yuzxa-UWEw-NTt-YBJ44-VN89-ARu-Fkt-HW.jpg",
  //   title: "Infinity...",
  //   time: "",
  //   technologies: ["More and more"],
  // },
  {
    institution: "Programming Hero",
    logo: "https://i.ibb.co/K7s9t44/programminghero-logo.jpg",
    title: "MERN Stack",
    time: "July 2022 - December 2022",
    technologies: [
      "React",
      "Tailwind",
      "JavaScript",
      "TypeScript",
      "Next",
      "Node & Express",
      "MongoDB & Mongoose",
    ],
  },
  {
    institution: "Bohubrihi",
    logo: "https://i.ibb.co/Pg5qQ6g/download.jpg",
    title: "Web Design",
    time: "April 2022 - June 2022",
    technologies: ["HTML", "CSS", "Bootstrap"],
  },
];

export const education = [
  {
    institution: "Govt. City College, Chittagong",
    logo: "https://i.ibb.co/Rc8tLLk/download.png",
    degree: "Bachelor of Social Science (BSS)",
    time: "November 2021 - Present",
    location: "Chittagong, Bangladesh",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque neque debitis sint dicta soluta consequuntur modi eveniet impedit accusantium. Maxime!",
  },
  {
    institution: "Shah Mohsen Aulia College",
    logo: "https://i.ibb.co/PZ6dt2Q/305489053-558603879394624-8579628661727179893-n.jpg",
    degree: "HIgher Secondary Certificate",
    time: "July 2018 - June 2020",
    location: "Chittagong, Bangladesh.",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Impedit, facilis optio! Similique ducimus quisquam facere sequi atque, placeat nulla qui?",
  },
];
