import React, { useEffect, useState } from "react";
// import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
// import ScrollAnimation from "react-animate-on-scroll";
import "react-circular-progressbar/dist/styles.css";
import "animate.css/animate.min.css";
import SectionHeading from "../../components/common/SectionHeading/SectionHeading";
import UseMaxWidth from "../../components/common/UseMaxWidth/UseMaxWidth";

const SkillsIcons = () => {
  const [skills, setSkills] = useState([]);
  useEffect(() => {
    fetch("skills.json")
      .then((res) => res.json())
      .then((data) => setSkills(data));
  }, []);

  const categories = [
    "Frontend",
    "Backend",
    "Programming language",
    "Database",
    "Hosting",
    "Design",
    "Tools",
  ];

  return (
    <div className="bg-black p-5 lg:p-10">
      <UseMaxWidth>
        <SectionHeading title="My" subTitle="Skills" />

        {categories?.map((category, idx) => (
          <div key={idx}>
            <h2 className="text-2xl font-semibold my-5">{category}</h2>
            <div className="grid grid-cols-2 md:grid-cols-6 gap-5 lg:gap-10 lg:grid-cols-8">
              {skills
                .filter((s) => s?.category === category)
                .map((skill, id) => {
                  const { img, name } = skill || {};
                  return (
                    <div
                      key={id}
                      className="relative bg-white rounded-md cursor-pointer h-[160px]"
                    >
                      <div className="">
                        <img className="py-10 px-10" src={img} alt={name} />
                        <h1 className="absolute bottom-1 left-[50%] translate-x-[-50%] text-center font-semibold text-xl mb-5 text-black">
                          {name}
                        </h1>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        ))}
      </UseMaxWidth>

      {/* <ScrollAnimation animateIn="fadeIn">Some Text</ScrollAnimation> */}
    </div>
  );
};

export default SkillsIcons;
