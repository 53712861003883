import React, { useState } from "react";
import { useEffect } from "react";
import ProjectLoading from "../../components/ProjectLoading";

import Project from "./Project";
import SectionHeading from "../../components/common/SectionHeading/SectionHeading";
import UseMaxWidth from "../../components/common/UseMaxWidth/UseMaxWidth";

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    fetch(`/projects.json`)
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        setProjects(data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <ProjectLoading />;
  }

  return (
    <UseMaxWidth>
      <div className="lg:mr-16 mb-10 lg:mb-0">
        <SectionHeading title="My" subTitle="Portfolio" />

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 px-5 lg:p-10 mt-5">
          {projects?.map((project) => (
            <Project key={project.id} project={project} />
          ))}
        </div>
      </div>
    </UseMaxWidth>
  );
};

export default Projects;
