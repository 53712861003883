import {
  Timeline,
  TimelineItem,
  TimelineConnector,
  TimelineHeader,
  TimelineIcon,
  TimelineBody,
  Typography,
  Avatar,
} from "@material-tailwind/react";
import { HiOutlineAcademicCap } from "react-icons/hi";
import { education } from "../../../data/data";

const EducationTimeline = () => {
  return (
    <div className="w-full">
      <h2 className="text-2xl my-5 font-bold flex items-center gap-2">
        <HiOutlineAcademicCap size={30} />
        Education
      </h2>
      <Timeline>
        {education?.map(
          (
            { institution, logo, degree, time, location, description },
            idx,
            edu
          ) => (
            <TimelineItem key={idx}>
              {idx !== edu?.length - 1 && <TimelineConnector />}
              <TimelineHeader>
                <TimelineIcon className="p-0">
                  <Avatar size="sm" src={logo} alt={institution} withBorder />
                </TimelineIcon>
                <Typography variant="h5" color="white">
                  <h2 className="font-semibold">{institution}</h2>
                </Typography>
              </TimelineHeader>
              <TimelineBody className="pb-8">
                <Typography color="gary" className="font-normal text-gray-400">
                  <h3 className="font-semibold text-lg text-gray-300">
                    {degree}
                  </h3>
                  <p className="text-sm italic">{time}</p>
                  <p className="my-3 text-white font-medium">{location}</p>
                  <p className="text-justify">{description}</p>
                </Typography>
              </TimelineBody>
            </TimelineItem>
          )
        )}
      </Timeline>
    </div>
  );
};

export default EducationTimeline;
