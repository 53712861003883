import React from "react";
import { Link } from "react-router-dom";
import Typical from "react-typical";
import UseMaxWidth from "../../components/common/UseMaxWidth/UseMaxWidth";

const Banner = () => {
  return (
    <>
      <UseMaxWidth>
        <div className="lg:mb-0">
          <div className="grid grid-cols-1 lg:grid-cols-[2fr_3fr] gap-x-5">
            <div className="px-5 lg:px-7 flex justify-center items-center">
              {/* <img
              src="https://i.ibb.co/YXpRyQm/Md-Morshedul-Islam-PH-1.jpg"
              alt=""
              className="rounded-[25px] w-full mt-5 border-2 border-primary"
            /> */}
              <div className="morshed-blob"></div>
            </div>
            <div className="flex justify-center items-center lg:h-screen p-5 lg:p-10">
              <div>
                <p>
                  <span className="text-3xl font-bold my-5 block"> Hi👋,</span>
                </p>
                <h2 className="text-2xl lg:text-3xl text-primary font-semibold">
                  I'M Md Morshedul Islam.
                </h2>
                <h5 className="text-2xl lg:text-4xl font-bold my-3">
                  <Typical
                    steps={[
                      "Next.js Developer",
                      1500,
                      "React Developer",
                      1500,
                      "WordPress Developer",
                      1500,
                      "Frontend Developer",
                      1500,
                      "Web Developer",
                      2000,
                      "MERN Stack Developer",
                      1500,
                    ]}
                    loop={Infinity}
                    wrapper="h2"
                  />
                </h5>
                <p className="my-5 md:text-lg lg:pr-20 text-justify">
                  I'm Md Morshedul Islam, a MERN Stack Web Developer. Proficient
                  in HTML5, CSS3, Bootstrap5, and Tailwind CSS, I specialize in
                  crafting dynamic web experiences. With a solid foundation in
                  React, React Router, and JavaScript (ES6), I'm skilled in
                  integrating Firebase for Authentication. Additionally, I'm
                  adept at Next.js, TypeScript, Node.js, Express (MVC), MongoDB,
                  and Mongoose. My expertise is exemplified through hands-on
                  project work across these technologies.
                </p>
                <div className="flex justify-between lg:justify-start gap-x-5">
                  <button className="border rounded-3xl border-primary py-1 px-3 text-primary lg:text-md hover:bg-blue-600 hover:text-white hover:ease-in hover:delay-300">
                    <a
                      href={
                        "https://drive.google.com/file/d/1izhiOYZVz4HAzajSUeyRuYq5ncklULWZ/view"
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      Get Resume
                    </a>
                  </button>
                  <button className="border rounded-3xl border-primary py-1 px-3 text-primary lg:text-md hover:bg-blue-600 hover:text-white hover:ease-in hover:delay-300">
                    <Link to={"/contact"}>Hire Me</Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </UseMaxWidth>
    </>
  );
};

export default Banner;
