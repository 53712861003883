import React from "react";
import { BsTrophy } from "react-icons/bs";

const Achievements = () => {
  const achievements = [
    {
      name: `${new Date().getFullYear() - 2023}+`,
      value: "Years of Experience",
    },
    { name: "7+", value: "Completed Projects" },
  ];

  return (
    <div className="p-5 lg:p-10">
      <h2 className="text-2xl font-bold mb-5 flex items-center space-x-2">
        <BsTrophy className="text-white" /> <span>Achievements</span>
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        {achievements.map((achievement, i) => (
          <div key={i} className="border border-white-2 rounded-lg p-8">
            <p className="text-5xl font-bold text-primary">
              {achievement.name}
            </p>
            <p className="text-xl pl-5 uppercase">{achievement?.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Achievements;
