import React from "react";
import { FaRegUserCircle } from "react-icons/fa";

const PersonalInfos = () => {
  const infos = [
    { name: "First Name: ", value: "Md Morshedul" },
    { name: "Last Name: ", value: "Islam" },
    { name: "Age: ", value: `${new Date().getFullYear() - 2000} Years` },
    { name: "Nationality: ", value: "Bangladesh" },
    { name: "Freelance: ", value: "Available", color: "text-green" },
    { name: "Address: ", value: "Chittagong, BD." },
  ];
  return (
    <div className="p-5 lg:p-10">
      <h2 className="text-2xl font-bold mb-5 flex items-center gap-2">
        <FaRegUserCircle />
        Personal Info
      </h2>
      <ul className="grid grid-cols-2">
        {infos.map((info, i) => (
          <li key={i} className={`text-text text-md my-2`}>
            {info.name}
            <span
              className={`${
                info.color ? info.color : "text-white"
              } font-semibold block lg:inline-block`}
            >
              {info.value}
            </span>
          </li>
        ))}
        <li className="text-text text-md my-2">
          Phone:{" "}
          <span className="text-white font-semibold block lg:inline-block">
            <a href="tel:+8801861952640">+88 01861952640</a>
          </span>
        </li>
        <li className="text-text text-md my-2">
          Language:{" "}
          <span className="text-white font-semibold block lg:inline-block">
            Bangla, English
          </span>
        </li>
        <li className="text-text text-md my-2">
          Email:{" "}
          <span className="text-white font-semibold block lg:inline-block">
            <a href="mailto:morshed952640@gmail.com">morshed952640@gmail.com</a>
          </span>
        </li>
      </ul>
    </div>
  );
};

export default PersonalInfos;
