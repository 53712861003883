import React from "react";
import Achievements from "./achievement";
import PersonalInfos from "./PersonalInfos";
// import Skills from "./Skills";
import "react-circular-progressbar/dist/styles.css";
import SkillsIcons from "./SkillsIcons";
import ExperienceTimeline from "./ExperienceTimeline/ExperienceTimeline";
import EducationTimeline from "./EducationTimeline/EducationTimeline";
import LearningTimeline from "./LearningTimeline/LearningTimeline";
import SectionHeading from "../../components/common/SectionHeading/SectionHeading";
import UseMaxWidth from "../../components/common/UseMaxWidth/UseMaxWidth";

const AboutMe = () => {
  return (
    <div className="py-20 mb-10 lg:mb-0">
      <SectionHeading title="About" subTitle="Me" />

      <div className=" grid grid-cols-1 lg:grid-cols-2 gap-5 p-3 lg:p-10">
        <UseMaxWidth>
          <PersonalInfos />
        </UseMaxWidth>
        <UseMaxWidth>
          <Achievements />
        </UseMaxWidth>
      </div>

      <UseMaxWidth>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5 p-5 lg:p-10">
          <ExperienceTimeline />
          <LearningTimeline />
          <EducationTimeline />
        </div>
      </UseMaxWidth>
      {/* <Skills></Skills> */}
      <SkillsIcons />
    </div>
  );
};

export default AboutMe;
