import {
  Timeline,
  TimelineItem,
  TimelineConnector,
  TimelineHeader,
  TimelineIcon,
  TimelineBody,
  Typography,
  Avatar,
} from "@material-tailwind/react";
import { GiJourney } from "react-icons/gi";
import { journey } from "../../../data/data";

const LearningTimeline = () => {
  return (
    <div className="w-full">
      <h2
        // data-aos="fade-up"
        className="text-2xl my-5 font-semibold flex items-center gap-2"
      >
        <GiJourney size={28} />
        Learning Journey
      </h2>
      <Timeline>
        {journey?.map(
          ({ institution, title, logo, time, technologies }, idx, jou) => (
            <TimelineItem key={idx}>
              {idx !== jou.length - 1 && <TimelineConnector />}
              <TimelineHeader>
                <TimelineIcon className="p-0">
                  <Avatar size="sm" src={logo} alt={institution} withBorder />
                </TimelineIcon>
                <Typography variant="h5" color="white">
                  <h2>{institution}</h2>
                </Typography>
              </TimelineHeader>
              <TimelineBody className="pb-8">
                <Typography color="gary" className="font-normal text-gray-400">
                  <h3 className="font-semibold text-lg text-gray-300">
                    {title}
                  </h3>
                  <p className="text-sm italic">{time}</p>
                  <p className="mt-3">
                    {technologies?.map((tech, idx, techs) => (
                      <span key={idx} className="text-white">
                        {tech} {idx !== techs?.length - 1 && "|"}{" "}
                      </span>
                    ))}
                  </p>
                </Typography>
              </TimelineBody>
            </TimelineItem>
          )
        )}
      </Timeline>
    </div>
  );
};

export default LearningTimeline;
